import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/About/AboutView.vue'
import OrganizersView from '@/views/About/OrganizersView.vue'
import RegistrationView from '@/views/Attend/RegistrationView.vue'
import VenueView from '@/views/Attend/VenueView.vue'
import ArtsAndPerformanceView from '@/views/Participate/ArtsAndPerformanceView.vue'
import DemoView from '@/views/Participate/DemoView.vue'
import GraduateStudentView from '@/views/Participate/GraduateStudentView.vue'
import PapersView from '@/views/Participate/PapersView.vue'
import PictorialsView from '@/views/Participate/PictorialsView.vue'
import StudioView from '@/views/Participate/StudioView.vue'
import ProgramView from '@/views/Program/ProgramView.vue'
import StudioProgramView from '@/views/Program/StudioProgramView.vue'
import StudentDesignCompetition from '@/views/Participate/StudentDesignCompetition.vue'
import WIPView from '@/views/Participate/WIPView.vue'
import CallForProposalView from '@/views/About/CallForProposalView.vue'
import LastingImpactAward from '@/views/About/LastingImpactAward.vue'
import StudentWorkerView from '@/views/Participate/StudentWorkerView.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeView
    },
    {
      path: '/about/about',
      name: 'About',
      component: AboutView
    },
    {
      path: '/about/organizers',
      name: 'Organizers',
      component: OrganizersView
    },
    {
      path: '/call_for_proposals',
      name: 'Call for proposals',
      component: CallForProposalView
    },
    {
      path: '/about/lasting_impact_award',
      name: 'Lasting impact award',
      component: LastingImpactAward
    },
    {
      path: '/program/program',
      name: 'Program',
      component: ProgramView
    },
    {
      path: '/program/studio',
      name: 'Studio Program',
      component: StudioProgramView
    },
    {
      path: '/participate/papers',
      name: 'Papers',
      component: PapersView
    },
    {
      path: '/participate/pictorials',
      name: 'Pictorials',
      component: PictorialsView
    },
    {
      path: '/participate/arts_and_performance',
      name: 'Arts and Performance',
      component: ArtsAndPerformanceView
    },
    {
      path: '/participate/studio',
      name: 'Studio',
      component: StudioView
    },
    {
      path: '/participate/demo_arrangement',
      name: 'Demo',
      component: DemoView
    },
    {
      path: '/participate/graduate_student_consortium',
      name: 'Graduate Student Consortium',
      component: GraduateStudentView
    },
    {
      path: '/participate/student_design_competition',
      name: 'Student Design Competition',
      component: StudentDesignCompetition
    },
    {
      path: '/participate/work_in_progress',
      name: 'Work In Progress',
      component: WIPView
    },
    {
      path: '/participate/student_volunteers',
      name: 'Student Workers',
      component: StudentWorkerView
    },
    {
      path: '/attend/registration',
      name: 'Registration',
      component: RegistrationView
    },
    {
      path: '/attend/venue',
      name: 'Venue',
      component: VenueView
    },
    {
      path: '/diversity/accessibility',
      name: 'Accessibility',
      component: VenueView
    },
    {
      path: '/diversity/child_care',
      name: 'Child Care',
      component: VenueView
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

export default router
