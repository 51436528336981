<template>
  <main>
    <h1>Studio</h1>
    <h2>Important Dates</h2>

    <table>
      <thead>
        <tr>
          <th>
            Event
          </th>
          <th>
            Deadline
          </th>
        </tr>
      </thead>
      <tr>
        <td>
          Submission deadline
        </td>
        <td>
          October 21, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Notification of acceptance
        </td>
        <td>
          November 18, 2024 AoE
        </td>
      </tr>
      <tr>
        <td>
          Camera-ready deadline
        </td>
        <td>
          December 9, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          TEI 2025 studios
        </td>
        <td>
          March 4, 2025
        </td>
      </tr>
    </table>
    <div>
      <a href="https://time.is/Anywhere_on_Earth">Check your local time in AoE.</a>
    </div>

    <h2>Studios at TEI 2025</h2>
    <p>
      Hello and welcome to TEI 2025 Studio track. We invite proposals for one-day Studio activities, which will take place on Tuesday March 4, hosted in the campus <a href="https://www.eirlab.net/">FabLab - EirLab</a> and at the <a href="https://scrime.u-bordeaux.fr/">SCRIME studio</a>.
    </p>
    <p>
      EirLab is a shared space that brings together a diverse community to transform ideas into concrete prototypes. It offers a digital fabrication workshop that provides prototyping tools such as 3D printing, laser cutting and engraving, waterjet cutting, and electronic circuit production.The SCRIME is a studio for creation and research in acoustics and experimental music. It will open its rooms for TEI studios requesting specific sound installations
    </p>

    <p v-if="false">
      More information about these locations can be read in the <a href="https://tei.acm.org/2025/about/venue">venue page</a>.
    </p>

    <p>
    <b>Studios</b> are a format unique to the TEI conference, providing researchers the opportunity to deeply engage with the materials, methods and processes used in their research. Traditionally, studios provide hands-on experiences during which participants are introduced to new technologies, materials, prototypes, or design approaches.
    </p>
    <p>
    Studios act as a shared space for examining theory and methodology related to tangible, embodied and embedded interaction. We invite researchers from all career stages to discuss and develop a specific topic of relevance to the TEI community. Studio proposals should contain a clear theoretical grounding around a problem or opportunity, describe a plan for applied engagement with the topic, and highlight how the outcome will provide important insights for TEI researchers and practitioners. Proposals need to clearly specify how such Studio results will be communicated and archived. Please contact studio chairs for discussing dissemination options at the conference.
    </p>
    <p>
      We particularly encourage studio proposals that would contribute to the TEI’25 theme on sustainability. We are looking for studios that would support discussion and commitment to more sustainable interaction design, research practices, and organization of the scientific community. Sustainability topics could include contributions that address questions around climate change, repair and reuse culture, impact of techniques and technologies, open and reproducible research, inclusion and accessibility of interaction…
    </p>
    <p>
      For reference, <a href="https://tei.acm.org/2024/index.php/studios/">you can check the 2024 accepted Studios</a>!
    </p>

    <h2>Organisational</h2>
    <p>
    Studio proposals are considered non-archival publications, which means that copyright automatically remains with the authors. Accepted proposals will, however, be made available through the ACM Digital Library.
    </p>
    <p>
    At least one author of each accepted Studio submission must register for the conference and the studio before the early registration deadline, for the Studio to be included in the conference program and the final submission to be published in the conference proceedings. Studio organisers are expected to advertise their Studio and recruit participants themselves. A minimum number of registered participants will be required for a Studio to take place. All participants will need to register for the Studio they are attending.
    </p>
    <p>
    We intend for the Studios to be as accessible as possible. If you intend to submit a proposal and are not funded by a university, feel free to <b>contact</b> us to discuss alternative participation options.
    </p>

    <h2>Submission guidelines</h2>
    <b>FORMAT REQUIREMENTS</b>
    <p>
    A Studio proposal consists of two documents: a description document and a logistics document. The description document should be formatted according to the new ACM <a href="https://www.acm.org/publications/taps/word-template-workflow">“Review Submission Format”</a>. The logistics document should be formatted so as to best communicate the organisers’ intent for the Studio.
    </p>

    <b>DESCRIPTION DOCUMENT STUDIOS: 2000 WORDS MAXIMUM, EXCLUDING REFERENCES</b>

    <p>A successful proposal should include the following sections:</p>
    <ul>
      <li>
        Abstract
      </li>
      <li>
        Detailed proposal description
      </li>
      <li>
        Grounding in theory
      </li>
      <li>
        Materials to be explored
      </li>
      <li>
        Learning Goals
      </li>
      <li>
        Schedule
      </li>
      <li>
        Supporting documents/figures/materials
      </li>
    </ul>

    <b>LOGISTICS DOCUMENT: 2000 WORDS MAXIMUM</b>

    <p>Please make sure to address the following points:</p>

    <ul>
      <li>Event organiser bio(s)</li>
      <li>Proposed schedule (including detailed timeline)</li>
      <li>Bill of materials (as needed)</li>
      <li>Estimated budget (if needed, please note that these costs will be charged directly to the participants)</li>
      <li>Technical Requirements (equipment and space conditions)</li>
      <li>Plans for dissemination of studio results (website, online documentation, publications, etc)</li>
      <li>Participant recruitment strategy (consider online/offline strategies)</li>
      <li>A draft ‘Call for Participation’ (excluded from word count)</li>
    </ul>

    <b>SUBMISSION</b>
    <p>All proposals must be submitted electronically via the <a href="https://new.precisionconference.com/submissions">Precision Conference (PCS)</a> website. The track will open for submissions in September 2024.</p>

    <b>CONFIDENTIALITY</b>
    <p>Confidentiality of submissions will be maintained during the review process. All rejected submissions will be kept confidential in perpetuity. All submitted materials for accepted submissions will be kept confidential until the start of the conference.</p>

    <b>ATTENDANCE</b>
    <p>At least one author of each accepted Studio submission must register for the conference and the studio before the early registration deadline, for the Studio to be included in the conference program and the final submission to be published in the conference proceedings.</p>
    <p>Any conference participant who wishes to attend a Studio should first register to it through the conference registration system.
    </p>

    <h2>Studio Chairs</h2>
    Margot Vulliez, <i>Inria center of the University of Bordeaux, Auctus team</i>, France<br>
    Valérie Maquil, <i>Luxembourg Institute of Science and Technology (LIST)</i>, Luxembourg<br>
    Louis Bigo, <i>University of Bordeaux, Enseirb-Matmeca</i>, France<br>
    <br>
    For further questions please contact the Studio chairs at <a href="mailto:studio_chairs@tei.acm.org">studio_chairs@tei.acm.org</a>
  </main>
</template>

<script setup>
</script>

<style scoped>
table {
  max-width: 500px;
}
</style>
