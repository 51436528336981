<template>
  <main>
    <h1>Registration</h1>
  </main>
</template>

<script setup>
</script>

<style scoped>

</style>
