<template>
  <main>
    <h1>Venue</h1>
  </main>
</template>

<script setup>
</script>

<style scoped>

</style>
