<template>
  <main>
    <h1>Pictorials</h1>
    <h2>Important Dates</h2>

    <table>
      <thead>
      <tr>
        <th>
          Event
        </th>
        <th>
          Deadline
        </th>
      </tr>
      </thead>
      <tr>
        <td>
          Abstract deadline
        </td>
        <td>
          July 25, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Full submission deadline
        </td>
        <td>
          August 1, 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Video and Supplementary Material Deadline
        </td>
        <td>
          August 8 2024, AoE
        </td>
      </tr>
      <tr>
        <td>
          Notification of conditional acceptance
        </td>
        <td>
          October 10, 2024 AOE
        </td>
      </tr>
      <tr>
        <td>
          Camera-ready deadline
        </td>
        <td>
          November 14, 2024 AOE
        </td>
      </tr>
      <tr>
        <td>
          TEI 2025 conference
        </td>
        <td>
          March 4-7, 2025
        </td>
      </tr>
    </table>
    <p>There will be no extensions to the deadlines listed above.</p>
    <div>
      <a href="https://time.is/Anywhere_on_Earth">Check your local time in AoE.</a>
    </div>

    <p>Full papers and pictorials published at TEI 2025 are ACM archival publications and will be made available through the ACM in the Digital Library. Pictorials at TEI leverage the power of visual communication for physical design objects, documenting tangible research methods and results.</p>

    <h2>What are Pictorials?</h2>
    <p>
      Pictorials are archival research publications with the same weight as full papers. This format first appeared in the proceedings of the ACM DIS2014 conference to honour and recognise particular ways of documenting design knowledge that might not fit more conventional format outputs. In 2025, TEI proudly marks the 5th anniversary of the pictorial track at the conference.
    </p>
    <p>
    Pictorials are research papers in which the visual components (e.g., diagrams, sketches, illustrations, renderings, photographs, annotated photographs, and collages) play a significant role in conveying novel concepts and research knowledge contribution.
    </p>
    <p>
    At TEI, pictorials should report on tangible, embedded and embodied research, traversing different types of contribution, including digital fabrication, technical processes, new technological materials, tangible methods, and related theory.
    </p>

    <h2>What Pictorials are not</h2>
    <p>
    As this format is becoming increasingly popular in our community, we find it important to clarify the role of pictorials as knowledge production formats:
    </p>
    <ul>
      <li>
        Pictorials <b>are not to be confused with short papers.</b> If the contribution is incipient in any way, your research will likely be much better received in the work-in-progress track.
      </li>
      <li>
        Consequently, pictorials <b>are not works in progress</b>. Pictorials should offer a complete, well-articulated contribution where the communication of knowledge benefits from designerly, visual means.
      </li>
    </ul>
    <p>
      <span style="color:var(--warning)">⚠️</span><b>SOME IMPORTANT POINTS TO CONSIDER WHEN PREPARING YOUR PICTORIALS:</b>
    </p>
    <ol>
      <li>
      Does your work require a Pictorial format? Or would it benefit from a more traditional paper submission?
      </li>
      <li>
        Is the contribution original and complete? If not, you may want to consider a WIP submission instead.
      </li>
      <li>
        Are images/diagrams emphasised over text as the primary means of communicating the research contribution?
      </li>
      <li>
        Are the implications for the TEI research community clear and sound?
      </li>
      <li>
        Do images play a meaningful role in clearly presenting the idea? Or are they merely decorative?
      </li>
      <li>
        Does the placement of images and text compose a good narrative?
      </li>
    </ol>

    <h2>Policy on Use of Large Language Models</h2>
    <p>
    Text generated from a large-scale language model (LLM), such as ChatGPT, must be clearly marked where such tools are used for <b>purposes beyond editing the author’s own text</b>. Please carefully review the April 2023 <a href="https://www.acm.org/publications/policies/new-acm-policy-on-authorship">ACM Policy on Authorship</a> before you use these tools. The <a href="https://medium.com/sigchi/acm-publications-policy-guidance-for-sigchi-venues-87332173aad1">SIGCHI blog post</a> describes approaches to acknowledging the use of such tools and we refer to it for guidance. While we do not anticipate using tools on a large scale to detect LLM-generated text, we will investigate submissions brought to our attention and <b>desk-reject</b> papers where LLM use is not clearly marked.
    </p>

    <h2>What to submit?</h2>
    <p>
      This year, we want to reclaim our traditional focus on material knowledge. We expect submissions to be clearly framed within the rapidly developing tangible, embedded, and embodied interaction field. Works framed as <b>research through design and more technical contributions</b> are particularly welcome.
    </p>
    <p>
    Submissions may cover diverse types of topics that include (but are not limited to):
    </p>
    <ul>
      <li>
        Design decisions and methods employed to create new interactive techniques and tools.
      </li>
      <li>
        Artistic explorations or practice-based research in or through tangible computational media technologies to support new human-machine interactions.
      </li>
      <li>
        Perspectives on tangible, embedded, and embodied interaction range from arts to science and design to education.
      </li>
      <li>
        Other insights, lessons learned, practices or processes of designing and evaluating tangible, embedded, and embodied interaction technologies.
      </li>
    </ul>

    <h2>Submission Guidelines</h2>
    <p>
      Pictorials must be submitted in the TEI2025 Pictorials format (see below) and not exceed <b>12 pages</b>, excluding references. Submissions will be reviewed in a <b>double-blind process</b>, and authors must ensure that their names and affiliations do not appear on the submission. The author and affiliation sections of the template must be left blank.
    </p>

    <p>
    In case of acceptance, authors will be asked to provide a camera-ready copy that includes this information, along with any recommended improvements as suggested by the reviewers. The first page of the submission should include the submission’s title and a written <b>abstract of no more than 150 words</b> describing the background and contribution to the TEI community. Further written parts known from other conference formats, such as Introduction, Conclusion, Discussion, Acknowledgements, and References, are optional. The body of the submission should be an annotated visual composition, and we encourage submissions to use the format creatively. All submissions should be anonymous and submitted via the TEI2025 <a href="https://new.precisionconference.com/submissions">Precision Conference (PCS)</a> system by <b>July 25, 2024</b> (abstract and authors’ info) and <b>August 1, 2024</b> (full submissions). If available, we advise you to use the InDesign template. Otherwise, the MS Word or PowerPoint templates are also accessible options.
    </p>
    <ul>
      <li>
        <a href="https://tei.acm.org/2024/wp-content/uploads/2023/03/TEI2024-Pictorials-InDesign-template-Folder-20230303T133400Z-001.zip">InDesign template</a>
      </li>
      <li>
        <a href="https://tei.acm.org/2024/wp-content/uploads/2023/03/TEI2024-Pictorials-PowerPoint-template-20230303T133404Z-001.zip">PowerPoint template</a>
      </li>
      <li>
        <a href="https://tei.acm.org/2024/wp-content/uploads/2023/03/TEI2024-Pictorials-Word-template-20230303T133407Z-001.zip">Word template</a>
      </li>
    </ul>

    <p>
    PCS supports file sizes of up to 150 MB. However, we recommend reducing the resolution size so it is easier for reviewers to download the paper.
    </p>

    <b>Creating accessible PDFs from InDesign, Word, or PowerPoint</b>
    <p>
      Adobe has a great resource for creating <a href="https://helpx.adobe.com/indesign/using/creating-accessible-pdfs.html">accessible PDFs</a>. You can find tutorials from Microsoft if you are using <a href="https://support.microsoft.com/en-us/office/make-your-word-documents-accessible-to-people-with-disabilities-d9bf3683-87ac-47ea-b91a-78dcacb3c66d">Microsoft Word</a> or <a href="https://support.microsoft.com/en-us/office/create-accessible-pdfs-064625e0-56ea-4e16-ad71-3aa33bb4b7ed">Microsoft Powerpoint</a>.
    </p>

    <h2>Review and selection</h2>
    <p>
    The review process is the same as for the paper track. Pictorials will be reviewed in a double-blind process, where each submission will be assigned to two associate chairs (AC and 2AC), who will invite external reviewers to evaluate the quality of the submission and its appropriateness to the TEI community. Authors must ensure that their names and affiliations do not appear on the submitted papers. The author, affiliation, and acknowledgement sections of the template must be left blank. In preparation for the “camera-ready” submissions, all authors will receive instructions on how to prepare their pictorial for publication, including adding the authors, affiliation, and acknowledgements, along with any recommended improvements as suggested by the reviewers.
    </p>
    <p>
      Furthermore, all references should remain intact. If you previously published a paper and your current submission builds on that work, the reference – with authors – should appear in the references. Submissions should <b>NOT</b> have blank references (e.g., “12. REMOVED FOR REVIEWING”). We encourage authors to refer to their previous work in the third person. Further suppression of identity in the body of the paper, while encouraged, is left to the authors’ discretion.
    </p>
    <p>
    Confidentiality of submissions will be maintained during the review process. All rejected submissions will be kept confidential in perpetuity. All submitted materials for accepted submissions will be kept confidential until the start of the conference.
    </p>
    <p>
    For camera-ready submissions, we will ask that all authors make their pictorials accessible by ensuring the document follows best practices in accessibility. Please see the following attachment for more information: PDF accessibility guideline.
    </p>

    <h2>Attendance</h2>
    <p>
      In order for the final paper to be published in the conference proceedings, at least one author of each accepted submission must register for the conference by the early registration deadline.
    </p>

    <h2>Previous Pictorial examples</h2>

    <ul>
      <li>
        Fiona Bell, Joshua Coffie, and Mirela Alistar. 2024. <a href="https://doi.org/10.1145/3623509.3633386">Bio-Digital Calendar: Attuning to Nonhuman Temporalities for Multispecies Understanding.</a>
      </li>
      <li>
        Oliver Child, Ollie Hanton, Colin Kellett, Matt Sutton, Bruce Drinkwater, and Mike Fraser. 2024. <a href="https://doi.org/10.1145/3623509.3633387">Tangible Explorations of Sonolithography.</a>
      </li>
      <li>
        Çağlar Genç, Velvet Spors, Oğuz 'Oz Buruk, Mattia Thibault, Leland Masek, and Juho Hamari. 2024. <a href="http://doi.org/10.1145/3623509.3633390">Envisioning Transhuman Communication Research: Speculative Human Augmentation Technologies and Fictional Abstracts.</a>
      </li>
      <li>
        Lea Albaugh, Jesse T Gonzalez, and Scott E Hudson. 2024. <a href="http://doi.org/10.1145/3623509.3633400">Tensions and Resolutions in Hybrid Basketry: Joining 3D Printing and Handweaving.</a>
      </li>
      <li>
        Elzelinde Van Doleweerd, and Miguel Bruns Alonso. 2023. <a href="https://doi.org/10.1145/3569009.3572791">The Creation of a Holistic Interactive Dining Experience with Shape-Changing Food Materials at Restaurant Alchemist.</a>
      </li>
      <li>
        Mafalda Gamboa. 2023. <a href="https://doi.org/10.1145/3569009.3572797">My Body, My Baby, and Everything Else: An Autoethnographic Illustrated Portfolio of Intra-Actions in Pregnancy and Childbirth.</a>
      </li>
      <li>
        Dianya Mia Hua, Jeffrey Bardzell, and Shaowen Bardzell. 2023. <a href="https://doi.org/10.1145/3569009.3572803">Embodied Embroidery: Somaesthetic Interaction Design for Women’s Masturbation.</a>
      </li>
      <li>
        Karen Anne Cochrane, Kristina Mah, Anna Ståhl, Claudia Núñez-Pacheco, Madeline Balaam, Naseem Ahmadpour, and Lian Loke. 2022. <a href="http://doi.org/10.1145/3490149.3502262">Body Maps: A Generative Tool for Soma-based Design.</a>
      </li>
      <li>
        Rikke Hagensby Jensen, Enrique Encinas, and Dimitrios Raptis. 2022. <a href="http://doi.org/10.1145/3490149.3502257">Spicing It Up: From Ubiquitous Devices to Tangible Things Through Provocation.</a>
      </li>
      <li>
        Iddo Yehoshua Wald and Oren Zuckerman. 2021. <a href="https://dl.acm.org/doi/10.1145/3430524.3446066">Magnetform: A Shape-change Display Toolkit for Material-oriented Designers.</a>
      </li>
      <li>
        Sangwon Jung, Ruowei Xiao, Oğuz ‘Oz’ Buruk, and Juho Hamari. 2021. <a href="https://dl.acm.org/doi/10.1145/3430524.3446067">Designing Gaming Wearables: From Participatory Design to Concept Creation.</a>
      </li>
      <li>
        Sara Nabil and David Kirk. 2021. <a href="https://dl.acm.org/doi/10.1145/3430524.3446074">Decoraction: a Catalogue for Interactive Home Decor of the Nearest-Future.</a>
      </li>
    </ul>

    <h2>Pictorial Chairs</h2>
    Claudia Núñez-Pacheco, <i>Umeå University</i>, Sweden<br>
    Karen Cochrane, <i>University of Waterloo</i>, Canada<br>
    Miriam Sturdee, <i>University of St Andrews</i>, Scotland<br>
    <br>
    For further questions please contact the Pictorials chairs at <a href="mailto:pictorial_chairs@tei.acm.org">pictorial_chairs@tei.acm.org</a>

  </main>
</template>

<script setup>
</script>

<style scoped>
table {
  max-width: 500px;
}
</style>
